import { ref } from 'vue'
import { defineStore } from 'pinia'
import whiteLabelApi from '../api/whiteLabel.api'
import type { IWLServicesVisible } from '../models/WhiteLabel'

const initServicesVisible = () => ({
  is_borrowing_available: false,
  is_lending_available: false,
  is_p2p_available: false,
  is_trading_available: false,
  is_investing_available: false,
  is_acquiring_available: false,
  is_banking_available: false,
  is_contracts_available: false,
  is_video_guides_available: false,
  is_sbp_available: false,
  is_swift_available: false,
  is_visa_available: false,
  is_onboarding_companies_available: false,
  is_referral_bonus_available: false,
  is_maintenance: false
})

export default defineStore('services-visible', () => {
  const servicesVisible = ref<IWLServicesVisible>(initServicesVisible())

  const fetchServicesVisible = async (domain?: string) => {
    servicesVisible.value = await whiteLabelApi.fetchServicesVisible(domain)
  }

  return {
    servicesVisible,
    fetchServicesVisible,
    initServicesVisible
  }
})
