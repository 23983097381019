/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { css, getElement } from './dom.js'
import type { ComponentPublicInstance } from 'vue'

const scrollTargets = [
  null,
  document,
  document.body,
  document.scrollingElement,
  document.documentElement
]

export function getScrollTarget(
  el: HTMLElement | undefined,
  targetEl?: HTMLElement | ComponentPublicInstance | string | undefined
) {
  let target = getElement(targetEl)

  if (target === void 0) {
    if (el === void 0 || el === null) {
      return window
    }

    target = el.closest(
      '.overflow-auto,.overflow-scroll,.overflow-x-auto,.overflow-y-auto,.overflow-x-scroll,.overflow-y-scroll,.h-screen'
    )
  }

  return scrollTargets.includes(target) ? window : target
}

export function getScrollHeight(el: Window | HTMLElement) {
  return (el === window ? document.body : (el as HTMLElement)).scrollHeight
}

export function getScrollWidth(el: Window | HTMLElement) {
  return (el === window ? document.body : (el as HTMLElement)).scrollWidth
}

export function getVerticalScrollPosition(scrollTarget: Window | HTMLElement) {
  return scrollTarget === window
    ? window.scrollY || document.body.scrollTop || 0
    : (scrollTarget as HTMLElement).scrollTop
}

export function getHorizontalScrollPosition(
  scrollTarget: Window | HTMLElement
) {
  return scrollTarget === window
    ? window.scrollX || document.body.scrollLeft || 0
    : (scrollTarget as HTMLElement).scrollLeft
}

export function animVerticalScrollTo(
  el: Window | HTMLElement,
  to: number,
  duration = 0,
  prevTime?: DOMHighResTimeStamp
) {
  const prevTime_ = prevTime === 0 ? performance.now() : prevTime || 0

  const pos = getVerticalScrollPosition(el)

  if (duration <= 0) {
    if (pos !== to) {
      setScroll(el, to)
    }

    return
  }

  requestAnimationFrame(nowTime => {
    const frameTime = nowTime - prevTime_

    const newPos =
      pos + ((to - pos) / Math.max(frameTime, duration)) * frameTime

    setScroll(el, newPos)

    if (newPos !== to) {
      animVerticalScrollTo(el, to, duration - frameTime, nowTime)
    }
  })
}

export function animHorizontalScrollTo(
  el: HTMLElement,
  to: number,
  duration = 0,
  prevTime?: DOMHighResTimeStamp
) {
  const prevTime_ = (
    prevTime === void 0 ? performance.now() : prevTime
  ) as number

  const pos = getHorizontalScrollPosition(el)

  if (duration <= 0) {
    if (pos !== to) {
      setHorizontalScroll(el, to)
    }

    return
  }

  requestAnimationFrame(nowTime => {
    const frameTime = nowTime - prevTime_

    const newPos =
      pos + ((to - pos) / Math.max(frameTime, duration)) * frameTime

    setHorizontalScroll(el, newPos)

    if (newPos !== to) {
      animHorizontalScrollTo(el, to, duration - frameTime, nowTime)
    }
  })
}

export function setScroll(scrollTarget: Window | HTMLElement, offset: number) {
  if (scrollTarget === window) {
    window.scrollTo(window.scrollX || document.body.scrollLeft || 0, offset)

    return
  }

  ;(scrollTarget as HTMLElement).scrollTop = offset // eslint-disable-line
}

function setHorizontalScroll(
  scrollTarget: Window | HTMLElement,
  offset: number
) {
  if (scrollTarget === window) {
    window.scrollTo(offset, window.scrollY || document.body.scrollTop || 0)

    return
  }

  ;(scrollTarget as HTMLElement).scrollLeft = offset // eslint-disable-line
}

export function setVerticalScrollPosition(
  scrollTarget: Window | HTMLElement | undefined | null,
  offset: number,
  duration?: number
) {
  if (!scrollTarget) {
    scrollTarget = getElement('.main-content')
  }

  if (!scrollTarget) {
    return
  }

  if (duration) {
    animVerticalScrollTo(scrollTarget, offset, duration)

    return
  }

  setScroll(scrollTarget, offset)
}

export function setHorizontalScrollPosition(
  scrollTarget: HTMLElement,
  offset: number,
  duration?: number
) {
  if (duration) {
    animHorizontalScrollTo(scrollTarget, offset, duration)

    return
  }

  setHorizontalScroll(scrollTarget, offset)
}

let size: undefined | number

export function getScrollbarWidth() {
  if (size !== undefined) {
    return size
  }

  const inner = document.createElement('p'),
    outer = document.createElement('div')

  css(inner, {
    width: '100%',
    height: '200px'
  })

  css(outer, {
    position: 'absolute',
    top: '0px',
    left: '0px',
    visibility: 'hidden',
    width: '200px',
    height: '150px',
    overflow: 'hidden'
  })

  outer.appendChild(inner)

  document.body.appendChild(outer)

  const w1 = inner.offsetWidth

  outer.style.overflow = 'scroll'

  let w2 = inner.offsetWidth

  if (w1 === w2) {
    w2 = outer.clientWidth
  }

  outer.remove()
  size = w1 - w2

  return size
}

export function hasScrollbar(el: HTMLElement, onY = true) {
  if (!el || el.nodeType !== Node.ELEMENT_NODE) {
    return false
  }

  return onY
    ? el.scrollHeight > el.clientHeight &&
        (el.classList.contains('scroll') ||
          el.classList.contains('overflow-auto') ||
          ['auto', 'scroll'].includes(
            (window.getComputedStyle(el) as any)['overflow-y'] // getComputedStyle возвращает неверный тип в lib.dom.d.ts, поэтому тут any
          ))
    : el.scrollWidth > el.clientWidth &&
        (el.classList.contains('scroll') ||
          el.classList.contains('overflow-auto') ||
          ['auto', 'scroll'].includes(
            (window.getComputedStyle(el) as any)['overflow-x'] // getComputedStyle возвращает неверный тип в lib.dom.d.ts, поэтому тут any
          ))
}

export default {
  getScrollTarget,

  getScrollHeight,
  getScrollWidth,

  getVerticalScrollPosition,
  getHorizontalScrollPosition,

  animVerticalScrollTo,
  animHorizontalScrollTo,

  setVerticalScrollPosition,
  setHorizontalScrollPosition,

  getScrollbarWidth,
  hasScrollbar,
  setScroll
}
